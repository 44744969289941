<template>
    <router-link
        :to="lesson.access || isEditorCourse ? `/courses/${$route.params.course_id}/lesson/${lesson.id}` : ''"
        tag="div"
        class="course__item course__lesson"
        :class="
          lesson.studied ? 'course__item--complete' : 'course__item--active'
        "
    >
        <div class="course__lesson-dragicon" v-if="isEditorCourse && accessEdit">
            <i>
                <icon height="16" width="11">
                    <circle cx="2.375" cy="2" r="2"/>
                    <circle
                        cx="2.375"
                        cy="8"
                        r="2"
                    />
                    <circle cx="2.375" cy="14" r="2"/>
                    <circle cx="8.375" cy="2" r="2"/>
                    <circle
                        cx="8.375"
                        cy="8"
                        r="2"
                    />
                    <circle cx="8.375" cy="14" r="2"/>
                </icon>
            </i>
        </div>
        <div class="course__lesson-index">
            {{ moduleIndex + '.' + lessonIndex }}
        </div>
        <div class="course__lesson-title">{{ lesson.name }}</div>
        <i v-if="lesson.new_comment && isEditorCourse" class="course__lesson-comments-icon"/>
        <lesson-submenu
            :lesson-id="lesson.id"
            class="course__lesson-action item-admin-action"
            :data="lesson"
            v-if="isEditorCourse && accessEdit"
            :module-id="moduleId"
        />
        <span
            v-tooltip="{content: placeholders.COURSE_EDIT_FORBIDDEN, offset: 7}"
            v-else-if="isEditorCourse && !accessEdit"
            class="submenu__icon"
        />
        <router-link
            :to="lesson.access || isEditorCourse ? `/courses/${$route.params.course_id}/lesson/${lesson.id}` : ''"
            tag="button"
            v-if="course.started && course.access && !lesson.studied && !isEditorCourse"
            :disabled="!lesson.access"
            class="v-btn"
        >Изучить</router-link>
        <div v-else-if="!isEditorCourse && lesson.studied" class="course__lesson-action item-action"></div>
    </router-link>
</template>

<script>
import placeholders from 'assets/js/placeholders'

export default {
    name: 'CourseLesson',
    components: {
        Icon : () => import('@/components/icon/Icon'),
        LessonSubmenu : () => import('./LessonSubmenu')
    },
    props: ['lesson', 'course', 'isEditorCourse', 'moduleIndex', 'lessonIndex', 'moduleId', 'accessEdit'],
    data () {
        return {
            showDropdown: false,
            placeholders
        }
    },
}
</script>

<style lang="scss" scoped>
@import "#sass/v-style";

.course__lesson {
    user-select: none;

    &-index {
        margin-left: 10px;
    }

    &-title {
        word-break: break-word;
        margin-left: 10px;
        margin-right: auto;
    }

    &-link {
        cursor: pointer;
    }

    &-comments-icon {
        flex-shrink: 0;

        margin-left: 5px;
        margin-right: 24px;
        @include icon($new-comments-icon-grey, 20px);
        width: 20px;
        height: 19.5px;
    }

    &-action {

        button {
            margin-top: 0;
        }
    }
}
</style>
